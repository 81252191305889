<template>
  <div id="Activity">
    <div ref="posFix">
      <van-tabs  v-model="activeName" @click="onClick"  @disabled="onClickDisabled" style="z-index:10;position: fixed;width: 100%;">
        <!-- <van-tab title="问答"  name="e"> -->
          <!-- <div class="directSeeding">
            <van-search
              v-model="search"
              shape="round"
              background="#23252E"
              placeholder="请输入搜索关键词"
              @input = focusChange
              style="flex:1"
            />
          </div> -->
        <!-- </van-tab> -->
        <van-tab title="人脉圈"  name="f">
          <!-- <div class="posFix">
            <div class="topSearch">
              <van-search
                v-model="search"
                shape="round"
                background="#23252E"
                placeholder="请输入搜索关键词"
                @input = focusChange
                style="flex:1"
              />
            </div>
        
            <div class="hotMain" >
              <div class="forth">
                <div>
                  <span @click="sortShow = !sortShow;show = false" :class="{'white':sortShow}">
                    <van-icon v-if="sortShow" name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(90deg);" />
                    <van-icon v-else name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(-90deg);" />
                    综合排序
                  </span>
                </div>
                <div>
                  <span @click="show = !show;sortShow=false" :class="{'white':show}">
                    <img v-if="!show" src="../assets/image/findOut/flitGray.png" /><img v-if="show" src="../assets/image/findOut/flitWhite.png" />筛选</span>
                </div>
              </div>
            </div>
          </div> -->
        </van-tab>
        <van-tab title="公司库"  name="g">

        </van-tab>
        <van-tab title="线下活动" name="c">
          <div class="posFix">
            <div class="topSearch">
              <van-search
                v-model="search"
                shape="round"
                background="#23252E"
                placeholder="请输入搜索关键词"
                @input = focusChange
                style="flex:1"
              />
            </div>
        
            <div class="hotMain" >
              <div class="forth">
                <div>
                  <span @click="sortShow = !sortShow;cityShow = false;timeShow = false" :class="{'white':sortShow}">
                    <van-icon v-if="sortShow" name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(90deg);" />
                    <van-icon v-else name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(-90deg);" />
                    综合排序
                  </span>
                  <span @click="sortShow = false;cityShow = !cityShow;timeShow = false" :class="{'white':cityShow}">
                    <van-icon v-if="cityShow" name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(90deg);" />
                    <van-icon v-else name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(-90deg);" />
                    全部区域
                  </span>
                  <!-- <span @click="sletters = true;sortShow = false;cityShow = false;timeShow = true" :class="{'white':timeShow}">
                    <van-icon v-if="show" name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(90deg);" />
                    <van-icon v-else name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(-90deg);" />
                    时间排序
                  </span> -->
                </div>
                <div>
                </div>
              </div>
            </div>
            <!-- <div class="listThree">
              <div class="list" @click.stop="toProgrammeDetail">
                <img src="../assets/image/home/index1.png"/>
                <div>
                  <div >保观学堂定期邀请保险行业内的朋友分享自己的观点</div>
                  <div>
                    <span>上海</span>
                    <span>38人已报名</span></div>
                  <div>
                    <span>06/18（周五）</span>
                    <span @click.stop="toForms">免费报名</span>
                  </div>
                </div>
              </div>
              <div class="list">
                <img src="../assets/image/home/index1.png"/>
                <div>
                  <div >保观学堂定期邀请保险行业内的朋友分享自己的观点</div>
                  <div>
                    <span>上海</span>
                    <span>38人已报名</span>
                  </div>
                  <div>
                    <span>06/18（周五）</span>
                    <span>免费报名</span>
                  </div>
                </div>
              </div>
              <div class="list">
                <img src="../assets/image/home/index1.png"/>
                <div>
                  <div >保观学堂定期邀请保险行业内的朋友分享自己的观点</div>
                  <div>
                    <span>上海</span>
                    <span>38人已报名</span>
                  </div>
                  <div>
                    <span>06/18（周五=liu）</span>
                    <span class="active">已结束</span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </van-tab>
        <van-tab title="保观集市"  name="d">
          <div class="posFix">
            <div class="topSearch">
              <van-search
                v-model="search"
                shape="round"
                background="#23252E"
                placeholder="请输入搜索关键词"
                @input = focusChange
                style="flex:1"
              />
            </div>
        
            <div class="hotMain" >
              <div class="forth">
                <div>
                  <span @click="sortShow=!sortShow" :class="{'white':sortShow}">
                    <van-icon v-if="sortShow" name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(90deg);" />
                    <van-icon v-else name="play" style="margin:0.02rem 0.05rem 0 0.02rem;transform: rotate(-90deg);" />
                    排序
                  </span>
                </div>
                <div>
                </div>
              </div>
            </div>
            <!-- <div class="listThree">
              <div class="list" @click.stop="toProgrammeDetail">
                <img src="../assets/image/home/index1.png"/>
                <div>
                  <div >众安保险|招聘</div>
                  <div>
                    <span>热招<span class="blue">保险规划师、售后客服</span>等6个职位</span>
                  </div>
                  <div>
                    <span>2021/06/17</span>
                    <span class="eye"><van-icon  name="eye-o" style="margin:0.02rem 0.05rem 0 0.02rem;" />1215</span>
                  </div>
                </div>
              </div>
              
            </div> -->
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div class="blackFix" ref="blackFix"></div>
    <div ref="topMost"></div>
    <van-list 
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="listOne" v-show="activeName == 'a' ||!activeName">
        <div @click.stop="toLiveDetail(i.id,i.livetime_start,i.type,i.address)" v-for="(i,item) in getDocList" :key="item" >
          <img :src="i.thumbimgurl ? Global.changeImg(i.thumbimgurl) :'../assets/image/home/index.png'" />
          <div v-if="i.livetime_start">
            <!-- <p v-if=" Date.parse(new Date())>Date.parse(new Date((i.livetime_start).replace(/-/g, '/'))) && !i.livetime_end"><span class="arocess1">直播中</span>{{i.livetime_start.split(' ')[0]}} 正在直播中...</p>
            <p v-else-if="Date.parse(new Date())<Date.parse(new Date((i.livetime_start).replace(/-/g, '/')))"><span class="arocess2">预约中</span>{{i.livetime_start}}~{{i.livetime_end}}</p> -->
            <p v-if="i.type== 1"><span class="arocess2">预约中</span>{{i.livetime_start}}~{{i.livetime_end}}</p> 
            <p v-if="i.type== 2"><span class="arocess3">回放</span>{{i.livetime_start.split(' ')[0]}} {{Global.formatDateL((i.livetime_start).replace(/-/g, '/'),(i.livetime_end).replace(/-/g, '/'))}}</p>
            <p>{{i.title}}</p>
          </div>
        </div>
      </div>
      <div class="listTwo" v-show="activeName == 'b'">
        <div class="list" v-for="(j,itemj) in getDocList" :key="itemj" @click.stop="toCoursesDetail(j.id)" >
          <img :src="j.thumbimgurl ? Global.changeImg(j.thumbimgurl) :'../assets/image/home/index1.png'" />
          <div>
            <div ><span>最新</span>{{j.name}}</div>
            <div>{{j.summary}}</div>
            <div v-if="j.tag">{{j.tag.name}}</div>
            <div>
              <span>¥{{j.price}}<span>{{j.pay_log_count}}人已学习</span></span>
              <span v-if="j.pay_log">{{j.pay_log.length? '已订阅' :'立即订阅'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="listThree" v-if="activeName == 'c'">
        <div class="list" v-for="(k,itemj) in getDocList" :key="itemj" @click.stop="toProgrammeDetail(k.id,k.deadline,k.sign,k.activity_apply_list,k.pay_log)" >
          <img :src="k.thumbimgurl ? Global.changeImg(k.thumbimgurl) :'../assets/image/home/index1.png'" />
          <div>
            <div >{{k.title}}</div>
            <div>
              <span>{{k.activity_location}}</span>
              <!-- <span>{{k.apply_user_count}}人已报名</span> -->
            </div>
            <div>
              <span>报名截止：{{k.deadline.split(' ')[0]}}（{{getWeek($moment(k.deadline).day())}}）</span>
              <!-- <span  v-if="k.sign == 3"  class="active" @click.stop="$toast('该活动已结束')">活动结束</span>
              <span @click.stop="toEnd(k.id,k.pay_log.id)"  v-else-if="k.pay_log&&k.activity_apply_list.length && k.activity_apply_list[0].status == 1 && Date.parse(new Date())<Date.parse(new Date((k.deadline).replace(/-/g, '/')))">未支付</span>
              <span @click.stop="toEnd(k.id)"  v-else-if="k.activity_apply_list.length && k.activity_apply_list[0].status == 2 && Date.parse(new Date())<Date.parse(new Date((k.deadline).replace(/-/g, '/')))">报名成功</span>
              <span  @click.stop="$toast('报名已结束')" v-else-if="Date.parse(new Date())>Date.parse(new Date((k.deadline).replace(/-/g, '/')))"  class="active">报名结束</span>
              <span  v-else>开始报名</span> -->
            </div>
          </div>
        </div>
      </div>

      <div class="listThree" v-show="activeName == 'd'">
        <div class="list" v-for="(k,itemj) in getDocList" :key="itemj" @click.stop="toMarketDetail(k.authority,k.id)">
          <img class="wid" :src="k.thumbimgurl ? Global.changeImg(k.thumbimgurl) :'../assets/image/home/index1.png'" />
          <div>
            <div >{{k.title}}</div>
            <div>
              <span>{{k.subhead}}</span>
              <!-- <span>热招<span class="blue">保险规划师、售后客服</span>等6个职位</span> -->
            </div>
            <div>
              <span>{{Global.fromatTimestamp(k.created_at)}}</span>
              <span class="eye"><van-icon  name="eye-o" style="margin:0.02rem 0.05rem 0 0.02rem;" />{{k.views}}</span>
            </div>
          </div>
        </div>
      </div>

    </van-list>
    <div>
      <van-popup v-model="show" position="top" style="width:100%">
        <div class="topPop">
          <div class="titleTab">
            <div :class="{'active':tag == ''}" @click="checkTag(' ')">全部</div>
            <div :class="{'active':tag == i.id}" v-for="(i,item) in getTags" :key="item" @click="tag = i.id">{{i.name}}</div>
          </div>
          <div class="anscontain"><span @click="tag = ''">重置</span><span @click="checkTag()">完成</span></div>
        </div>
      </van-popup>
      <van-popup v-model="sortShow" position="top" style="width:100%">
        <div class="sort" v-if="activeName == 'b'">
          <p :class="{'active':sort == ''}" @click="checkSort('')">智能排序</p>
          <p :class="{'active':sort == 2}" @click="checkSort(2)">最新发布</p>
          <p :class="{'active':sort == 1}" @click="checkSort(1)">热门课程</p>
        </div>
        <div class="sort" v-if="activeName == 'c'">
          <p :class="{'active':sort == ''}" @click="checkSort('')">智能排序</p>
          <p :class="{'active':sort == 2}" @click="checkSort(2)">最新活动</p>
          <p :class="{'active':sort == 1}" @click="checkSort(1)">热门活动</p>
        </div>
        <div class="sort" v-if="activeName == 'd'">
          <p :class="{'active':sort == 1}" @click="checkSort(1)">时间正序</p>
          <p :class="{'active':sort == 2}" @click="checkSort(2)">时间倒叙</p>
          <p :class="{'active':sort == 3}" @click="checkSort(3)">浏览倒叙</p>
          <p :class="{'active':sort == 4}" @click="checkSort(4)">浏览量</p>
        </div>
      </van-popup>
      <van-popup v-model="cityShow" position="top" style="width:100%">
        <div class="sort" >
          <p :class="{'active':city == ''}" @click="checkCity('')">全部</p>
          <p :class="{'active':city == '北京'}" @click="checkCity('北京')">北京</p>
          <p :class="{'active':city == '上海'}" @click="checkCity('上海')">上海</p>
          <p :class="{'active':city == '杭州'}" @click="checkCity('杭州')">杭州</p>
        </div>
      </van-popup>
    </div>

    <div class="fixedBottom" @click="LiveList" v-show="activeName == 'a' ||!activeName">
      更多回放，点击查看
    </div>

    <span class="addMore" @click="addLine" v-if="activeName == 'd'">
      <van-icon style="font-size:0.23rem;" color="#F6F7FD" name="plus" />
    </span>
    
  </div>
</template>
<script>
export default {
  name: 'Activity',
   data(){
    return{
      show:false,
      letters:false,
      value:'',
      activeName: 'a',//默认选项
      getDocList:'',//列表
      loading:false,
      finished:false,
      next_page_url:'',
      search:'',
      sort:'',
      city:'',
      time:'',
      tag:'',
      getTags:'',
      sortShow:false,
      cityShow:false,
      timeShow:false
    }
  },
  beforeMount(){
    if(this.$route.query.activeName){
      this.activeName = this.$route.query.activeName
    }
  },
  mounted(){
    this.first()
  },
  methods:{
    first(){
      this.axios({
        method: 'GET',
        url: '/quiz/getTags',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.getTags = res.data.data
        }
      })
      var url;
      this.activeName = this.$route.query.activeName

      if(this.activeName == 'a' || !this.activeName){
        url = '/live/getLiveList?search='+this.search
      }else if(this.activeName == 'b'){
        url = '/series/getSeriesList'
      }else if(this.activeName == 'c'){
        url = '/activity/getActivityList'
      }else if(this.activeName == 'd'){
        url = '/market/getMarketList?sort='+this.sort+'&search='+this.search
      }


      this.axios({
        method: 'GET',
        url: url,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.getDocList = res.data.data.data
          this.loading = false
          this.finished = false
          this.next_page_url = res.data.data.next_page_url

          this.$refs.blackFix.style.top = this.$refs.posFix.children[0].offsetHeight + 'px'
          this.$refs.topMost.style.paddingTop = this.$refs.posFix.children[0].offsetHeight + this.$refs.blackFix.offsetHeight+'px'
        }
      })
    },
    onClickDisabled(){
      this.$toast('暂未开放');
    },
    onClick(name, title){
      if(name == 'e'){
        this.$router.push({
          path: '/FindOut'
        })
      }else if(name == 'f'){
        this.$router.push({
          path: '/Connections'
        })
      }else if(name == 'g'){
        this.$router.push({
          path: '/CompanyList'
        })
      }else{
        console.log(name,title)
        this.$router.push({
          path: '/ActivityLine?activeName='+name
        })
        this.activeName = name
        this.sortShow = false
        this.cityShow = false
        this.show = false
        this.search = ''
        this.sort = ''
        // this.firstLoad()
      }

    },
    firstLoad(){
      window.scrollTo(0,0)
      var url;
      console.log(this.activeName,!this.activeName)
      if(this.activeName == 'a' || !this.activeName){
        url = '/live/getLiveList?search='+this.search
      }else if(this.activeName == 'b'){
        url = '/series/getSeriesList?type='+this.sort+'&tag='+this.tag+'&search='+this.search
      }else if(this.activeName == 'c'){
        url = '/activity/getActivityList?search='+this.search+'&sort='+this.sort+'&city='+this.city+'&time='+this.time  
      }else if(this.activeName == 'd'){
        url = '/market/getMarketList?sort='+this.sort+'&search='+this.search
      }
      this.finished = false
      this.axios({
        method: 'GET',
        url: url,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.getDocList = []
          if(res.data.data){
            this.getDocList = res.data.data.data;
            this.next_page_url = res.data.data.next_page_url
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
            this.finished = true
          }
          this.$refs.blackFix.style.top = this.$refs.posFix.children[0].offsetHeight + 'px'
          this.$refs.topMost.style.paddingTop = this.$refs.posFix.children[0].offsetHeight + this.$refs.blackFix.offsetHeight+'px'
        }
      })
    },
    onLoad(){
      if(this.next_page_url){
        var url;
        if(this.activeName == 'a' || !this.activeName){
          url = this.next_page_url+'&search='+this.search
        }else if(this.activeName == 'b'){
          url = this.next_page_url+'&type='+this.sort+'&tag='+this.tag+'&search='+this.search
        }else if(this.activeName == 'c'){
          url = this.next_page_url+'&search='+this.search+'&sort='+this.sort+'&city='+this.city+'&time='+this.time  
        }else if(this.activeName == 'd'){
          url = this.next_page_url+'&sort='+this.sort+'&search='+this.search
        }
        this.axios({
          method: 'GET',
          url: url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.data)
              this.next_page_url = res.data.data.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
    addLine(){
      this.$router.push({
        path: '/Market',
      })
    },
    toCoursesDetail(id){
      this.$router.push({
        path: '/CoursesDetail?seriesId='+id,
      })
    },
    LiveList(){
      this.$router.push({
        path: '/LiveList',
      })
    },
    toMarketDetail(authority,id){
      // if(authority == 2){
        this.axios({
          method: 'PATCH',
          url:'/market/addMarketViews/'+id,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then(()=>{
          this.$router.push({
            path: '/MarketDetail?id='+id,
          })
        })
        
      // }else{
      //   this.$toast('请先购买会员')
      // }
    },
    toLiveDetail(id,time,type,address){
      // 预告
      if(type == 1){
         this.$router.push({
          path: '/LiveDetail?id='+id,
        })
      }else{
        window.location.href = address
      }
      // 预约中的直播，跳转详情
      // if(Date.parse(new Date())<Date.parse(new Date((i.livetime_start).replace(/-/g, '/')))){
        // this.$router.push({
        //   path: '/LiveDetail?id='+id,
        // })
      // }
      // else{
      //   // 跳转连接
      //   window.location.href =link
      // }
      
    },
    // 跳转活动详情
    toProgrammeDetail(activityId,deadline,sign,activity_apply_list,payId){
      console.log(activityId,deadline,sign,activity_apply_list,payId)
      // 跳转到活动详情页的只有  报名成功和去报名
      if(sign == 3){
        this.$toast('该活动已结束')
      }else if(activity_apply_list.length && activity_apply_list[0].status ==1  && Date.parse(new Date())<Date.parse(new Date((deadline).replace(/-/g, '/')))){
        this.$router.push({  //待支付
          path: '/SignUpEnd?activityId='+activityId+'&payId='+payId.id+'&type=c',
        })
      }else if(activity_apply_list.length && activity_apply_list[0].status ==2  && Date.parse(new Date())<Date.parse(new Date((deadline).replace(/-/g, '/')))){
        this.$router.push({  //报名成功
          path: '/ProgrammeDetail?activityId='+activityId,
        })
      }else if(Date.parse(new Date())>Date.parse(new Date((deadline).replace(/-/g, '/')))){
        this.$toast('报名已结束')
      }else{
        this.$router.push({
          path: '/ProgrammeDetail?activityId='+activityId,
        })
      }
    },
    // 支付与报名情况
    toEnd(activityId,payId){
      this.$router.push({
        path: '/SignUpEnd?activityId='+activityId+'&payId='+payId+'&type=c',
      })
    },
    checkTag(){
      this.next_page_url = ''
      this.sortShow = false
      this.cityShow = false
      this.timeShow = false
      this.letters = false
      this.show = false
      this.firstLoad()
    },
    checkSort(a){
      this.sort = a;
      this.next_page_url = ''
      this.sortShow = false
      this.cityShow = false
      this.timeShow = false
      this.letters = false
      this.show = false

      this.firstLoad()
    },
    checkCity(a){
      this.city = a;
      this.next_page_url = ''
      this.sortShow = false
      this.cityShow = false
      this.timeShow = false
      this.letters = false
      this.show = false

      this.firstLoad()
    },
    checkTime(a){
      this.time = a;
      this.next_page_url = ''
      this.sortShow = false
      this.cityShow = false
      this.timeShow = false
      this.letters = false
      this.show = false

      this.firstLoad()
    },
    focusChange(){
      this.firstLoad()
    },

    getWeek(a){
      switch(a){
        case 1:
        return '周一';
        case 2:
        return '周二';
        case 3:
        return '周三';
        case 4:
        return '周四';
        case 5:
        return '周五';
        case 6:
        return '周六';
        case 0:
        return '周日';
      }
    }
  },
  watch: {
    '$route': 'first'
  }
}
</script>
<style>
.listThree{
  padding:  0.15rem;
  background: #23252E;
}
</style>
<style lang="less" scoped>

#Activity{
   .addMore{
    position: fixed;
    bottom: 0.8rem;
    width: 0.45rem;
    height: 0.45rem;
    background: linear-gradient(345deg, #3890FF 0%, #1B78EE 100%);
    box-shadow: 0px 0px 5px rgba(9, 104, 223, 0.39);
    border-radius: 50%;
    right: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      font-weight: 900;
    }
  }
  .fixedBottom{
    left: 0;
    right: 0;
    margin: auto;
    position: fixed;
    bottom: 0.7rem;
    width: 1.4rem;
    height: 0.27rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F6F7FD;
    background: #3890FF;
    border-radius: 0.27rem;
    font-size: 0.12rem;
  }
  .blackFix{
    z-index: 3;
    background: #16171D;
    height: 0.11rem;
    position: fixed;
    top: 1.4rem;
    width: 100%;
  }
  .posFix{
    position: relative;
    padding-bottom: 0.15rem;
    z-index: 10;
    border-top:1px solid #5C5D6E61;
    max-width: 750px;
  }
  .listThree{
    padding:  0.15rem;
    background: #23252E;
    .blue{
      color: #3890FF;
    }
    >div:last-child{
      border: none;
    }
    >div{
      padding: 0.15rem 0;
      display: flex;
      align-items: center;
      border-bottom:.1px solid #5C5D6E61;
      .wid{
        width: 1.03rem;
        object-fit: cover;
      }
      >img{
        max-width: 1.03rem;
        height: 0.72rem;
        margin-right: 0.12rem;
        border-radius: 0.05rem;
      }
      >div{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        color: #F6F7FD;
        >div:last-child{
          display: flex;
          align-items: center;
          justify-content: space-between;
          >span:first-child{
            color: #989AB1;
            font-size: 0.12rem;
          }
          // >span:last-child{
          //   height: 0.25rem;
          //   background: #3890FF;
          //   color: #FFFFFF;
          //   text-align: center;
          //   line-height: 0.25rem;
          //   padding: 0 0.13rem;
          //   font-size: 0.12rem;
          //   border-radius: 0.25rem;
          //   transform: scale(0.9);
          // }
          >span.active{
            background: #9091A3;
          }
          >span.eye{
            background: none;
            color: #727387;
            transform: none;
          }
        }
        >div:nth-child(2){
          color: #727387;
          font-size: 0.12rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0.08rem 0 0.08rem;
        }
        >div:first-child{
          color: #F6F7FD;
          font-size: 0.14rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .listTwo{
    padding: 0.15rem;
    background: #23252E;
    >div:last-child{
      border: none;
    }
    >div{
      padding: 0.15rem 0;
      display: flex;
      align-items: center;
      border-bottom:.1px solid #5C5D6E61;
      >img{
        max-width: 1.03rem;
        height: 0.72rem;
        margin-right: 0.12rem;
      }
      >div{
        display: flex;
        flex-direction: column;
        flex: 1;
        color: #F6F7FD;
        >div:last-child{
          display: flex;
          align-items: center;
          justify-content: space-between;
          >span:first-child{
            color: #FF4A4A;
            font-size: 0.12rem;
            >span{
              color: #989AB1;
              margin-left: 0.05rem;
              font-size: 0.12rem;
              transform: scale(0.8);
            }
          }
          >span:last-child{
            height: 0.25rem;
            background: #3890FF;
            color: #FFFFFF;
            text-align: center;
            line-height: 0.25rem;
            padding: 0 0.13rem;
            font-size: 0.12rem;
            border-radius: 0.25rem;
            transform: scale(0.9);
          }
        }
        >div:nth-child(3){
          color: #727387;
          font-size: 0.12rem;
        }
        >div:nth-child(2){
          color: #9091A3;
          font-size: 0.12rem;
          margin: 0.04rem 0 0.06rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        >div:first-child{
          display: flex;
          color: #F6F7FD;
          font-size: 0.14rem;
          >span{
            transform: scale(0.8);
            margin-right: 0.04rem;
            font-size: 0.12rem;
            color: #FFAC41;
            border: 1px solid #FFAC41;
            border-radius: 0.02rem;
            padding: 0.02rem 0.04rem;
          }
        }
      }
    }
  }
  .sort{
    padding: 0.1rem 0.3rem 0.05rem;
    >p{
      color: #989AB1;
      font-size: 0.12rem;
      margin-bottom: 0.12rem;
    }
    .active{
      color: #3890FF;
    }
  }
  .interests{
    display: flex;
    justify-content: space-between;
    >span{
      color: #989AB1;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      margin-right: 0.05rem;
      >img{
        width: 0.12rem;
        margin-right: 0.05rem;
      }
    }
  }
  .titleTop2{
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #989AB1;
    font-size: 0.14rem;
    font-weight: 600;
    >span{
      color: #F6F7FD;
      font-size: 0.12rem;
    };
  }
  .titleTab{
    padding-left: 0.15rem;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0.1rem;
    padding-top: 0.1rem;
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-right: 0.12rem;
      margin-bottom: 0.1rem;
    }
    .active{
      background: #3890FF;
      color: #F6F7FD;
    }
  }
  .topPop{
    .anscontain{
      display: flex;
      height: 0.4rem;
      border-top: 1px solid #5C5D6E61;
      >span{
        flex:1;
        text-align: center;
        line-height: 0.4rem;
        color: #989AB1;
        font-size: 0.14rem;
      }
      >span:nth-child(2){
        color: #3890FF;
        border-left: 1px solid #5C5D6E61;
      }
    }
  }
  .hotMain{
    .forth{
      display: flex;
      justify-content: space-between;
      padding:0 0.15rem 0;
        >div:last-child{
          >span{
            margin-left: 0.2rem;
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            >img{
              width: 0.12rem;
              margin:0.02rem 0.05rem 0 0.13rem;
            }
          }
          .white{
            color: #F6F7FD;
          }
        }
        >div:first-child{
          display: flex;
          font-size: 0.12rem;
          >span{
            color: #989AB1;
            margin-right: 0.2rem;
          }
          .white{
            color: #F6F7FD;
          }
        }
      }
  }
  .topSearch{
    display: flex;
    padding: 0.15rem;
    .right{
      display: flex;
      align-items: center;
      color: #9091A3;
      font-size: 0.12rem;
    }
  }
  
  .directSeeding{
    padding: 0.15rem;
    border-top: 1px solid #5C5D6E61;
    z-index: 10;
  }
  .listOne{
    padding: 0 0.15rem;
    >div{
      margin-top: 0.11rem;
      >img{
        width: 100%;
        height: 2.1rem;
        object-fit: cover;
        border-radius: 5px 5px 0 0;
      }
      >div{
        background: linear-gradient(89deg, #3D404F 0%, #3D404F 100%);
        border-radius: 0px 0px 5px 5px;
        padding: 0.1rem 0 0.1rem 0.12rem;
        >p:first-child{
          display: flex;
          align-items: center;
          margin-bottom: 0.05rem;
          font-size: 0.12rem;
          color: #989AB1;
          >span{
            padding: 0.02rem 0.05rem;
            font-size: 0.12rem;
            color: #F6F7FD;
            transform: scale(0.8);
            border-radius: 0.02rem;
            margin-right: 0.065rem;
          }
          .arocess1{
            background: linear-gradient(90deg, #FF636F 0%, #FE5E67 50%, #FC7277 100%);
          }
          .arocess2{
            background: linear-gradient(90deg, #FF7E4E 0%, #FF9544 100%);
          }
          .arocess3{
            background: linear-gradient(90deg, #3C94FF 0%, #4CADFF 100%);
          }
        }
        >p:last-child{
          color: #F6F7FD;
          font-size: 0.14rem;
        }
      }
    }
  }
  background: #23252E;
}
</style>